.url-report-control {
  display: flex;
  justify-content: space-between;
  .btn-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    button {
      height: 30px;
    }
  }
}
.add-url-description {
  font-weight: 100;
  font-size: 0.8rem;
  position: relative;
  top: -8px;
}
