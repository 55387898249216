@import '@shared/color';
.qEditor {
  margin: 10px 0 20px;
  position: relative;
}
.editorWrapper {
  position: relative;
  border: 1px solid $blue;
  border-radius: 4px;
  .qlContainer {
    min-height: 300px;
  }
}

.actionWrapper {
  display: flex;
  position: absolute;
  width: 100px;
  top: 2px;
  right: 10px;
  justify-content: space-evenly;
  i {
    font-size: 24px;
  }
  .save {
    z-index: $editableTextboxZIndex;
    background-color: $blue;
    color: $white;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    font-weight: bold;
    align-self: center;
    height: 32px;
    display: flex;
    width: 160px;
  }
}
.textContent {
  min-height: 20px;
  position: relative;
  padding: 8px 15px;
  cursor: pointer;
}
.textContent img {
  max-width: 100%;
}
.placeholderText {
  color: $gray-b;
  cursor: pointer;
  padding: 8px 15px;
}
.editIcon {
  color: #aaa;
  position: absolute !important;
  right: 10px;
}
