body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dim {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-80 {
  opacity: 0.8;
}

.highlighted-row {
  transition: background-color 0.5s ease;
  background-color: rgb(240, 240, 240);
}
.pre-line {
  white-space: pre-line;
}

.full-width {
  width: 100%;
}
.inline-radio,
.row > .inline-radio {
  margin: 10px 50px 10px 0;
  padding: 0.25rem 0.5rem;
  width: auto;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.highcharts-credits {
  opacity: 0 !important;
}
