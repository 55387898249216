body {
  --toastify-toast-width: 600px;
}

.dropdown-item-filter {
  cursor: pointer;
  font-size: 14px;
}
.bg-gray {
  background-color: #f4f4f4;
}

.ml-2 {
  margin-left: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.mr-1 {
  margin-right: 0.125rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.add-button,
.back-button {
  margin-left: 2em;
  font-size: 12px;
  font-weight: 600;
}

.main-sidebar {
  transform: none;
  transition: all 0.25s ease-in;
  position: sticky;
  z-index: 10;
}
.nav-link.overflow-unset {
  overflow: unset !important;
}
.spinner {
  animation: none !important;
  border: none !important;
}

.form-label {
  font-weight: 600;
}

a {
  text-decoration: none !important;
}

.campaigns-infor {
  margin-top: -11px;
  margin-bottom: 2em;
  font-size: 10px;
}

.global-search {
  margin-top: 4px;
}

.avatar-space-right {
  margin-right: 13px;
}

.form-error {
  color: #ff0000;
  font-weight: 300;
  font-size: 12px;
  margin: 4px 2px;
}

.error-message {
  margin-top: 6px !important;
}

.radio-div {
  margin-left: 17px;
}

.loader-four {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  float: left;
  min-height: 100%;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 9999;
}

.modal {
  margin: auto;
}

.submitoverwrite {
  margin-bottom: 500px;
}
.keppingloaderPlace {
  display: flex;
}

.url-table {
  width: 100%;
  background-color: transparent;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  caption-side: bottom;
  border-collapse: collapse;
}

.create-report-checkbox {
  margin-left: 0px;
}

.checkbox-label {
  margin-left: 10px;
}

.no-data {
  text-align: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.logout-button-color {
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.mx-px {
  margin-right: 1px;
  margin-left: 1px;
}

.max-w-xl {
  max-width: 28rem;
}

.width {
  min-width: 100px;
}
.widthh {
  min-width: 110px;
}

.td {
  white-space: nowrap;
  max-width: 50px;
  overflow-x: hidden;
}

i.row-icons {
  cursor: pointer;
  font-size: 18px;
}

td {
  vertical-align: middle;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-right {
  text-align: right !important;
}

tfoot td {
  border-bottom: none !important;
}

.keyword-title {
  margin-left: 11px;
  margin-top: 27px;
  font-weight: 500;
}

.keyword-rank-title {
  font-weight: 500;
  margin-left: 10px;
  margin-top: 11px;
}

.link {
  cursor: pointer;
  color: #3273dc;
}

.react-datepicker__triangle {
  left: -16px !important;
}
.picture-preview {
  border: 1px solid #ccc;
  padding: 2px;
  max-width: 300px;
}
.loader-size {
  transform: scale(1.75);
}
.icon-size {
  margin-right: 12px;
  font-size: 15px;
}
.fetched-table {
  overflow: auto;
}
.error-position {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-route {
  color: #000;
}
.logo {
  flex-shrink: 0;
}
.url-table-row {
  display: grid;
  grid-template-columns: 35px 120px 120px auto 50px 50px 85px 85px 75px 75px 75px 75px 65px 65px;
  text-align: center;
  align-items: center;
}
.url-table-row:nth-child(even) {
  background-color: #eee;
}
.url-table-row > div {
  padding: 8px 4px;
}
.modal-dialog-scrollable .modal-content {
  overflow: auto;
}
.background-footer-model {
  background-color: rgb(0, 0, 0, 0.05);
}
.cancel-bg {
  background-color: white;
}
.fetch-text-size {
  font-size: 18px;
}
.text-left {
  text-align: left;
}
